const initialState = {
  items: [],
};

export const cart = {
  namespaced: true,
  state: initialState,
  actions: {
    // Add to actions
    addSubscriptionToCart({ commit }, subscription) {
      // Change to use the same mutation as regular items
      commit("addToCart", subscription);
    },
    addToCart({ commit }, payload = null) {
      commit("addToCart", payload);
    },
    loadCart({ commit }, payload = null) {
      commit("loadCart", payload);
    },
    removeFromCart({ commit }, payload = null) {
      commit("removeFromCart", payload);
    },
    clearCart({ commit }, payload = null) {
      commit("clearCart");
    },
    saveCart({ commit }, payload = null) {
      const { item, index } = payload;
      console.log(item, index)
      commit("saveCart", item, index);
    }
  },
  mutations: {
    // Add to mutations
    saveCart(state, item, index) {
      state.items.splice(index, 1);
      state.items.push(item);
      localStorage.setItem("cart", JSON.stringify(state.items));
    },
    addToCart(state, item) {
      // Add ID if not present
      if (!item.id) {
        item.id = Date.now();
      }

      state.items.push(item);

      if (state.items.length > 0) {
        localStorage.setItem("cart", JSON.stringify(state.items));
      }
    },
    removeFromCart(state, index) {
      state.items.splice(index, 1);
      localStorage.setItem("cart", JSON.stringify(state.items));
    },
    loadCart(state, item) {
      const cart = JSON.parse(localStorage.getItem("cart"));
      if (cart && cart.length > 0) {
        state.items = cart;
      }
    },
    clearCart(state) {
      state.items.length = 0;
      localStorage.setItem("cart", null);
    },
  },
};
