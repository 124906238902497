import API_URL from "@/globals/api";
import axiosInstance from "./api";
import GLOBAL_URLS from "@/globals/stripe";


class SubscriptionService {
  getSubscriptions() {
    return axiosInstance.get(`${API_URL}/subscriptions/subscriptions`);
  }

  createPortalSession(userId) {
    return axiosInstance.get(`${GLOBAL_URLS.STRIPE_URL}/create-portal-session?user_id=${userId}`);
  }
}

export default new SubscriptionService();