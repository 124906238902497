<script setup>
import { ref, computed, onMounted } from "vue"
import { useStore } from 'vuex'
import CardComponent from "@/components/CardComponent.vue"
import { mdiCheck } from '@mdi/js'
import Icon from '@/components/Icon.vue'
import JbButton from '@/components/JbButton.vue'
import GLOBAL_URLS from "@/globals/stripe";

const store = useStore();

const userId = computed(() => store.state.auth.user.user.id)
const getClientReferenceId = computed(() => (window.Rewardful && window.Rewardful.referral || ('checkout_' + (new Date).getTime())))

const spinnerDisplayed = ref(false)

const plans = computed(() => {
    const products = store.state.products.deals || []
    return products
        .filter(product => product.plan_id_stripe) // Only include products with valid plan_id_stripe
        .map(product => ({
            name: product.name,
            price: product.price,
            productId: product.id,
            priceId: product.plan_id_stripe, // Use the Stripe plan ID
            popular: isPopular(product.tokens),
            popularLabel: getPopularLabel(product.tokens),
            labelColor: getLabelColor(product.tokens),
            features: [
                {
                    text: `${(product.tokens * 1.1).toFixed(1)} tokens per month`,
                    breakdown: `${product.tokens} + ${(product.tokens * 0.1).toFixed(1)} bonus`,
                    highlight: true
                }
            ]
        }))
})

const isPopular = (tokens) => {
    return [53, 320, 1100].includes(tokens)
}

const getPopularLabel = (tokens) => {
    switch (tokens) {
        case 53:
            return "Most popular"
        case 320:
            return "Most low budget value"
        case 1100:
            return "Most medium budget value"
        default:
            return ""
    }
}

const getLabelColor = (tokens) => {
    switch (tokens) {
        case 53:
            return "from-green-600 to-green-400"
        case 320:
            return "from-blue-600 to-blue-400"
        case 1100:
            return "from-purple-600 to-purple-400"
        default:
            return ""
    }
}

const subscribe = (productId, priceId) => {
    spinnerDisplayed.value = true
    const url = `${GLOBAL_URLS.STRIPE_URL}/create-subscription-session?plan=${priceId}&user_id=${userId.value}&product_id=${productId}&reference=${getClientReferenceId.value}`
    window.location.href = url
}

onMounted(() => {
  store.dispatch("products/getTokenProducts");
});
</script>

<template>
    <div class="mb-6 p-4 rounded-lg border border-yellow-200 bg-yellow-50 dark:bg-gray-800 dark:border-yellow-700">
        <p class="text-gray-700 dark:text-gray-300 leading-relaxed">
            <span class="font-medium">Important note about plans:</span> Your monthly allocation is added to your
            existing balance rather than resetting it. Once subscribed to a plan, you can only cancel or renew it -
            upgrading or downgrading between plans is not currently supported. To switch to a different plan, you'll
            need to first cancel your current subscription and then subscribe to the new plan.
        </p>
    </div>
    <div class="grid md:grid-cols-4 gap-6 mb-6">
        <div v-for="plan in plans" :key="plan.name" :class="`relative p-6 rounded-lg border shadow-lg transition-all duration-500 hover:scale-102
                   ${plan.popular ? 'bg-gradient-to-br from-blue-50 to-white dark:from-gray-800 dark:to-gray-700 border-blue-200 dark:border-blue-500' :
                'bg-white dark:bg-gray-800 dark:border-gray-700'}`">
            <span v-if="plan.popular"
                class="absolute -top-3 left-1/2 -translate-x-1/2 bg-gradient-to-r text-white px-4 py-1 rounded-full text-xs font-bold shadow-md animate-pulse"
                :class="plan.labelColor">
                {{ plan.popularLabel }}
            </span>

            <h3 :class="`mb-4 text-2xl font-bold ${plan.popular ? 'text-blue-600 dark:text-blue-400' : ''}`">
                {{ plan.name }}
            </h3>
            <div class="flex items-baseline mb-4">
                <span :class="`text-4xl font-extrabold ${plan.popular ? 'text-blue-600 dark:text-blue-400' : ''}`">
                    €{{ plan.price }}
                </span>
                <span class="ml-1 text-gray-500 dark:text-gray-400">/month</span>
            </div>

            <ul class="mb-8 space-y-4 text-left">
                <li v-for="feature in plan.features" :key="typeof feature === 'string' ? feature : feature.text"
                    class="flex items-start space-x-3 transition-all duration-300 ease-in-out hover:translate-x-1">
                    <Icon :path="mdiCheck"
                        :class="`w-5 h-5 flex-shrink-0 mt-1 ${plan.popular ? 'text-blue-500' : 'text-green-500'}`" />
                    <div>
                        <template v-if="typeof feature === 'string'">
                            <span>{{ feature }}</span>
                        </template>
                        <template v-else>
                            <span class="font-semibold">{{ feature.text }}</span>
                            <div class="text-sm mt-1">
                                <span class="text-gray-600 dark:text-gray-400">
                                    ({{ feature.breakdown.split('+')[0] }} </span><span
                                    class="font-medium text-green-600 dark:text-green-400">+ {{
                                        feature.breakdown.split('+')[1] }}</span>)
                                <span
                                    class="ml-1 inline-block bg-green-100 dark:bg-green-900 text-green-700 dark:text-green-300 text-xs px-2 py-0.5 rounded-full animate-pulse">
                                    Save 10% vs Pay-as-you-go
                                </span>
                            </div>
                        </template>
                    </div>
                </li>
            </ul>

            <jb-button :spinner="spinnerDisplayed" @click="subscribe(plan.productId, plan.priceId)"
                class="w-full transform transition-all duration-300 hover:shadow-lg hover:-translate-y-1"
                :class="plan.popular ? 'animate-shimmer bg-gradient-to-r from-blue-500 via-blue-600 to-blue-500 bg-[length:200%_100%]' : 'hover:bg-gray-100 dark:hover:bg-gray-700 dark:bg-gray-800 dark:text-white'"
                :color="plan.popular ? 'info' : 'white'" :label="`Get started with ${plan.name}`" />
        </div>
    </div>
</template>

<style scoped>
@keyframes shimmer {
    0% {
        background-position: 100% 0;
    }

    100% {
        background-position: -100% 0;
    }
}

.animate-shimmer {
    animation: shimmer 3s infinite linear;
}

@keyframes gentle-pulse {

    0%,
    100% {
        opacity: 0.9;
    }

    50% {
        opacity: 1;
    }
}

.animate-pulse {
    animation: gentle-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>